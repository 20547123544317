<template>
  <v-stepper v-model="payment_allocation_stepper">
    <!--Header -->
    <v-stepper-header>
      <template v-for="step_count in payment_allocation_steps">
        <v-stepper-step
          :key="`${step_count}-step`"
          :complete="payment_allocation_stepper > step_count"
          :step="step_count"
        >
          Step {{ step_count }}
        </v-stepper-step>
        <v-divider v-if="step_count !== steps" :key="step_count"></v-divider>
      </template>
    </v-stepper-header>
    <!-- End of Header -->
    <!-- Stepper Items-->
    <v-stepper-items>
      <template v-for="step_count in payment_allocation_steps">
        <v-stepper-content :step="step_count" :key="step_count">
          <AllocationStep
            :transaction="transaction"
            :step_count="step_count"
            @passModalClose="$emit('passModalClose', event)"
            @incrementStepper="payment_allocation_stepper++"
          />
        </v-stepper-content>
      </template>
    </v-stepper-items>
    <!-- End of Stepper Items-->
  </v-stepper>
</template>
<script>
export default {
  name: "AllocationStepper",
  props: ["transaction"],
  components: {
    AllocationStep: () => import("./AllocationStep.vue"),
  },
  data() {
    return {
      steps: null,
      payment_allocation_stepper: 1,
      payment_allocation_steps: [1, 2],
    };
  },
};
</script>